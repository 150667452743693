import React, {useContext, useEffect, useMemo, useState} from 'react';

import "../../styles/sleep-log-modal-styles.css";

import SleepLog, { SleepEpisode, SleepSummary } from '../../types/SleepLog';
import { ISleepLogUpdateFlusher } from '../../utils/SleepLogUpdateFlusher';
import { Modal, Button, Row, Col, OverlayTrigger, Tooltip, Nav, Collapse } from 'react-bootstrap';
import TagList from './TagList';
import SelectDrugComponent from './SelectDrugComponent';
import { LoginState } from '../../types/CommonInterfaces';
import { formatSleepLogTitleDate, FormatUtils, ratingColorMap } from '../../utils/FormatUtility';
import SleepLogModifier, { DrugType } from '../../types/SleepLogModifier';
import { StatusMessage, StatusMessageType } from '../common/StatusMessages';
import { Limits } from '../../constants/limits';
import FitbitSleepCard from './fitbit/FitbitSleepCard';
import DateTimeUtils from '../../utils/DateTimeUtils';
import SavingChanges from '../common/SavingChanges';
import SleepLogViewFactory from '../../types/SleepLogViewFactory';
import produce from 'immer';
import SleepStagesEditor from './SleepStagesEditor';
import SleepStagesCard from './SleepStagesCard';
import { useImmer } from 'use-immer';
import DeleteSleepLogDialog from './DeleteSleepLogDialog';
import ErrorBoundary from '../common/ErrorBoundary';
import WithingsSleepStagesCard from './WithingsSleepStagesCard';
import ISleepLogView from '../../types/ISleepLogView';
import SimpleTooltip from './common/SimpleTooltip';
import SleepStagesUtils from '../../utils/SleepStagesUtils';
import { ConnectedAccountType } from '../../types/AccountSettings';
import { SleepLogHttpServiceContext } from '../http/sleep-log-http-service.provider';
import useStore from '../../shared/store/useStoreService.hook';
import SleepLogSettingsService from '../sleep-log-settings/services/SleepLogSettingsService';
import { SleepLogSettingsServiceContext } from '../sleep-log-settings/services/sleep-log-settings-service.provider';
import { useTagSuggestionsService } from '../../services/TagSuggestionsService.provider';
import { DateTime } from 'luxon';
import _ from 'lodash';
import SimpleDate from '../../types/SimpleDate';

interface PropsType {
    loginState: LoginState;
    focusedSleepLog: SleepLog;
    show: boolean;
    isSaving: boolean;
    editable: boolean;
    recentSleepLogs?: ISleepLogView[];


    sleepLogUpdateFlusher: ISleepLogUpdateFlusher;

    handleClose: () => void;
    updateSleepLog: (sleepLog: SleepLog) => void;
    handleDeleteLog: (id: string) => void;
    setNextFocusedSleepLog: (curSleepLogId: string, direction: number) => void;
    addStatus: (msg: StatusMessage) => void;
    openSettings: () => void;
}

export default function SleepLogModal(props: PropsType) {
    const {
        loginState,
        updateSleepLog,
        addStatus,
        editable,
        recentSleepLogs,
        openSettings,
        sleepLogUpdateFlusher,
        handleClose,
        handleDeleteLog,
    } = props;

    const sleepLogHttpService = useContext(SleepLogHttpServiceContext)!;
    const [, settings] = useStore<SleepLogSettingsService, SleepLogSettingsService["state"]>(SleepLogSettingsServiceContext);
    const [tagSuggestionsService] = useTagSuggestionsService();

    const [sleepLog, setSleepLog] = useImmer(props.focusedSleepLog);
    useEffect(() => { setSleepLog(props.focusedSleepLog); }, [props.focusedSleepLog]);

    const sleepLogView = useMemo(() => SleepLogViewFactory.createView(sleepLog), [sleepLog]);
    const [showPrompt, setShowPrompt] = useState(() => sleepLog.date >= DateTimeUtils.calculateThresholdDate(2));
    const [showNotes, setShowNotes] = useState(true);
    const [notesNumRows, setNotesNumRows] = useState(10);
    const [isSyncingLogs, setIsSyncingLogs] = useState(false);
    const [showSleepEditor, setShowSleepEditor] = useState(false);
    const [sleepDataTab, setSleepDataTab] = useState<ConnectedAccountType | "summary">("summary");
    const [showAddMetric, setShowAddMetric] = useState(false);
    const [newMetricName, setNewMetricName] = useState("");
    const [areCustomValuesExpanded, setAreCustomValuesExpanded] = useState(true);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [threeDayMovingAverages, setThreeDayMovingAverages] = useState<{ hoursSlept?: number; rating?: number }>({
       hoursSlept: undefined,
       rating: undefined 
    });

    useEffect(() => {
        const propagateChanges = (event: any) => {
            updateSleepLog(sleepLog);
            handleClose();
        }

        // Handle navigation events away from modal and propagate properties
        // back to the sleep log table.
        window.addEventListener('popstate', propagateChanges);

        return () => {
            setTimeout(() => {
                window.removeEventListener('popstate', propagateChanges)
            }, 100);
        };
    }, [sleepLog])

    useEffect(() => {
        if (recentSleepLogs && recentSleepLogs.length > 0) {
            const sortDirection = recentSleepLogs[0].date.asString < recentSleepLogs[recentSleepLogs.length - 1].date.asString ? 1 : -1;

            const recentLogs: { date: SimpleDate; }[] = recentSleepLogs;
            const index = _(recentLogs).sortedIndexBy(
                sleepLogView,
                value => DateTime.fromISO(value.date.asString).valueOf() * sortDirection
            );

            const date = DateTime.fromISO(sleepLogView.date.asString);
            const mostRecentLogs: ISleepLogView[] = [];
            let start = index;

            function isWithinDays(other: ISleepLogView) {
                const otherDate = DateTime.fromISO(other.date.asString);
                const diff = date.diff(otherDate, "days").as("days");
                return diff <= 2 && diff >= 0;                
            }

            while (start >= 0 && start < recentSleepLogs.length && isWithinDays(recentSleepLogs[start])) {
                mostRecentLogs.push(start === index ? sleepLogView : recentSleepLogs[start]);
                start += (sortDirection * -1);
            }

            let hoursSleptAverage: number | undefined;
            const recentSleep: number[] = mostRecentLogs.filter(log => log.minutesAsleep !== undefined).map(log => log.minutesAsleep as number);
            
            if (recentSleep.length === 3) {
                hoursSleptAverage = recentSleep.reduce((prev, cur) => (prev + cur)) / recentSleep.length;
            }

            let ratingAverage: number | undefined;
            const recentRatings: number[] = mostRecentLogs.filter(log => log.rating !== undefined).map(log => log.rating as number); 
            
            if (recentRatings.length === 3) {
                ratingAverage = recentRatings.reduce((prev, cur) => (prev + cur)) / recentRatings.length;
            }
            
            setThreeDayMovingAverages({
                hoursSlept: hoursSleptAverage,
                rating: ratingAverage
            })
        }

    }, [sleepLogView, recentSleepLogs])    

    function addTag(tag: string) {
        const result = SleepLogModifier.addTag(sleepLog, tag);

        if (result.succeeded) {
            setSleepLog(result.sleepLog);

            const update = {tags: result.sleepLog.tags};            
            sleepLogUpdateFlusher.push(sleepLog.id, update);
            
            tagSuggestionsService.add('tags', tag, 1);                 
        }
        else {
            addStatus({
                type: StatusMessageType.Fail,
                msg: result.message
            });
        }

        return result.succeeded;
    }

    function moveTag(sourceIndex: number, targetIndex: number) {
        const result = SleepLogModifier.moveTag(sleepLog, sourceIndex, targetIndex);

        if (result.succeeded) {
            setSleepLog(result.sleepLog);

            const update = {tags: result.sleepLog.tags};            
            sleepLogUpdateFlusher.push(sleepLog.id, update);                 
        }
        else {
            addStatus({
                type: StatusMessageType.Fail,
                msg: result.message
            });
        }

        return result.succeeded;
    }    
    

    function deleteTag(index: number, tag: string) {
        const result = SleepLogModifier.deleteTag(sleepLog, index, tag);

        if (result.succeeded) {
            setSleepLog(result.sleepLog);

            const update = {tags: result.sleepLog.tags};
            sleepLogUpdateFlusher.push(sleepLog.id, update);
        }
        else {
            addStatus({
                type: StatusMessageType.Fail,
                msg: result.message
            });            
        }

        return result.succeeded;
    }

    function addEvent(eventName: string) {
        const result = SleepLogModifier.addEvent(sleepLog, eventName);

        if (result.succeeded) {
            setSleepLog(result.sleepLog);

            const update = {events: result.sleepLog.events};            
            sleepLogUpdateFlusher.push(sleepLog.id, update);
            
            tagSuggestionsService.add('events', eventName, 1);                 
        }
        else {
            addStatus({
                type: StatusMessageType.Fail,
                msg: result.message
            });
        }

        return result.succeeded;
    }

    function moveEvent(sourceIndex: number, targetIndex: number) {
        /**
         *  TODO: implement this method
         */
        return true;
    }    
    

    function deleteEvent(index: number, eventName: string) {
        const result = SleepLogModifier.deleteEvent(sleepLog, index, eventName);

        if (result.succeeded) {
            setSleepLog(result.sleepLog);

            const update = {events: result.sleepLog.events};
            sleepLogUpdateFlusher.push(sleepLog.id, update);
        }
        else {
            addStatus({
                type: StatusMessageType.Fail,
                msg: result.message
            });            
        }

        return result.succeeded;
    }


    function addSleepDrug(drugName: string) {
        const result = SleepLogModifier.addDrug(sleepLog, DrugType.Sleep, drugName);

        if (result.succeeded) {
            setSleepLog(result.sleepLog);

            const update = {medications: result.sleepLog.medications};
            sleepLogUpdateFlusher.push(sleepLog.id, update);

                // todo: there's a race condition where drugName can be null... look into this...
                if (!drugName) {
                    return false;
                }

                tagSuggestionsService.add('sleepMeds', drugName, 1);         
        }
        else {
            addStatus({
                type: StatusMessageType.Fail,
                msg: result.message
            });            
        }

        return result.succeeded;
    }

    function deleteSleepDrug(index: number, drugName: string) {
     const result = SleepLogModifier.deleteDrug(sleepLog, DrugType.Sleep, index, drugName);

     console.log(result);

     if (result.succeeded) {
        setSleepLog(result.sleepLog);

        const update = {medications: result.sleepLog.medications};
        sleepLogUpdateFlusher.push(sleepLog.id, update);
     }
     else {
        addStatus({
            type: StatusMessageType.Fail,
            msg: result.message
        });         
     }

     return result.succeeded;
    }

    function updateSleepDrug(oldValue: string, newValue: string, index: number) {
        const result = SleepLogModifier.updateDrug(sleepLog, DrugType.Sleep, index, oldValue, newValue);

        if (result.succeeded) {
            setSleepLog(result.sleepLog);

            const update = {medications: result.sleepLog.medications};
            sleepLogUpdateFlusher.push(sleepLog.id, update);
        }

        return result.succeeded;
    }

    function updateSleepDrugDose(dose: string, index: number, drugName: string) {
        const result = SleepLogModifier.updateDrugDose(sleepLog, DrugType.Sleep, index, drugName, dose);

        if (result.succeeded) {
            setSleepLog(result.sleepLog);

            const update = {medications: result.sleepLog.medications};
            sleepLogUpdateFlusher.push(sleepLog.id, update);
        }

        return result.succeeded;
    }

    function changeSleepDrugTakenAt(index: number, takenAt: string) {
        const result = SleepLogModifier.changeTakenAtTime(sleepLog, DrugType.Sleep, index, takenAt);

        if (result.succeeded) {
            setSleepLog(result.sleepLog);

            const update = {medications: result.sleepLog.medications};
            sleepLogUpdateFlusher.push(sleepLog.id, update);
        }

        return result.succeeded;
    }

    function addOtherDrug(drugName: string) {
        const result = SleepLogModifier.addDrug(sleepLog, DrugType.Other, drugName);

        console.log(result);

        if (result.succeeded) {
            setSleepLog(result.sleepLog);

            const update = {otherMedications: result.sleepLog.otherMedications};
            sleepLogUpdateFlusher.push(sleepLog.id, update);
            
            tagSuggestionsService.add('otherMeds', drugName, 1);                        
        }
        else {
            addStatus({
                type: StatusMessageType.Fail,
                msg: result.message
            });            
        }
        
        return result.succeeded;
    }

    function deleteOtherDrug(index: number, drugName: string) {
        const result = SleepLogModifier.deleteDrug(sleepLog, DrugType.Other, index, drugName);
 
        if (result.succeeded) {
         setSleepLog(result.sleepLog);
 
         const update = {otherMedications: result.sleepLog.otherMedications};
         sleepLogUpdateFlusher.push(sleepLog.id, update);
        }
        else {
            addStatus({
                type: StatusMessageType.Fail,
                msg: result.message
            });            
        }
        
        return result.succeeded;
    }
    
    function updateOtherDrug(oldValue: string, newValue: string, index: number) {
        const result = SleepLogModifier.updateDrug(sleepLog, DrugType.Other, index, oldValue, newValue);

        if (result.succeeded) {
            setSleepLog(result.sleepLog);

            const update = {otherMedications: result.sleepLog.otherMedications};
            sleepLogUpdateFlusher.push(sleepLog.id, update);
        }
        
        return result.succeeded;        
    }

    function updateOtherDrugDose(dose: string, index: number, drugName: string) {
        const result = SleepLogModifier.updateDrugDose(sleepLog, DrugType.Other, index, drugName, dose);

        console.log(result);

        if (result.succeeded) {
            setSleepLog(result.sleepLog);

            const update = {otherMedications: result.sleepLog.otherMedications};
            sleepLogUpdateFlusher.push(sleepLog.id, update);
        }

        return result.succeeded;        
    }    

    function changeOtherDrugTakenAt(index: number, takenAt: string) {
        const result = SleepLogModifier.changeTakenAtTime(sleepLog, DrugType.Other, index, takenAt);

        if (result.succeeded) {
            setSleepLog(result.sleepLog);

            const update = {otherMedications: result.sleepLog.otherMedications};
            sleepLogUpdateFlusher.push(sleepLog.id, update);
        }

        return result.succeeded;        
    }

    function addFeeling(feeling: string) {
        const result = SleepLogModifier.addFeeling(sleepLog, feeling);

        if (result.succeeded) {
            setSleepLog(result.sleepLog);

            const update = {feelings: result.sleepLog.feelings};
            sleepLogUpdateFlusher.push(sleepLog.id, update);
            
            tagSuggestionsService.add('feelings', feeling, 1);
        }
        else {
            addStatus({
                type: StatusMessageType.Fail,
                msg: result.message
            });            
        }

        return result.succeeded;        
    }

    function deleteFeeling(index: number, feeling: string) {
        const result = SleepLogModifier.deleteFeeling(sleepLog, index, feeling);

        if (result.succeeded) {
            setSleepLog(result.sleepLog);

            const update = {feelings: result.sleepLog.feelings};
            sleepLogUpdateFlusher.push(sleepLog.id, update);
        }
        else {
            addStatus({
                type: StatusMessageType.Fail,
                msg: result.message
            });            
        }

        return result.succeeded;        
    }

    function moveFeelingTag(sourceIndex: number, targetIndex: number) {
        const result = SleepLogModifier.moveFeelingTag(sleepLog, sourceIndex, targetIndex);

        if (result.succeeded) {
            setSleepLog(result.sleepLog);

            const update = {feelings: result.sleepLog.feelings};
            sleepLogUpdateFlusher.push(sleepLog.id, update);
        }
        else {
            addStatus({
                type: StatusMessageType.Fail,
                msg: result.message
            });            
        }

        return result.succeeded;        
    }

    function updateFeeling(newValue: string, index: number, oldValue: string) {
        const result = SleepLogModifier.updateFeeling(sleepLog, index, oldValue, newValue);

        console.log(result);

        if (result.succeeded) {
            setSleepLog(result.sleepLog);

            const update = {feelings: result.sleepLog.feelings};
            sleepLogUpdateFlusher.push(sleepLog.id, update);
        }

        return result.succeeded;
    }    

    function deleteLog() {
        setShowDeleteConfirmation(false);
        handleClose();
        handleDeleteLog(sleepLog.id);
    }

    function closeModal() {
        handleClose();
        updateSleepLog(sleepLog);
    }

    function changeFocusedSleepLog(direction: number) {
        updateSleepLog(sleepLog);
        props.setNextFocusedSleepLog(sleepLog.id, direction);
    }

    function handleRatingChange(rating: string | undefined) {
        const ratingVal = rating ? Number(rating) : undefined;
        const result = SleepLogModifier.updateRating(sleepLog, ratingVal);

        if (result.succeeded) {
            setSleepLog(result.sleepLog);

            const update = {rating: ratingVal === undefined ? null : ratingVal};
            sleepLogUpdateFlusher.push(sleepLog.id, update);
        }    
    }

    function onNotesChange(updatedNotes: string) {
        const result = SleepLogModifier.updateNotes(sleepLog, updatedNotes);

        if (result.succeeded) {
            setSleepLog(result.sleepLog);

            const update = {notes: updatedNotes};
            sleepLogUpdateFlusher.push(sleepLog.id, update);
        }
    }

    function setPrimaryConnectedAccount(account: ConnectedAccountType) {
        const result = SleepLogModifier.setPrimaryConnectedAccount(sleepLog, account);

        if (result.succeeded) {
            setSleepLog(result.sleepLog);

            const update = {primaryConnectedAccount: account};
            sleepLogUpdateFlusher.push(sleepLog.id, update);
        }        
    }

    async function syncSleepLog() {
        if (loginState.loggedIn) {

            setIsSyncingLogs(true);

            try {
                const syncedSleepLog = await sleepLogHttpService.syncSleepLog(sleepLog.id);

                if (syncedSleepLog.lastModifiedTime) {
                    sleepLogUpdateFlusher.setLastModifiedTime(syncedSleepLog.id, syncedSleepLog.lastModifiedTime);
                }                    

                setSleepLog(syncedSleepLog);
            }
            catch (e) {
                addStatus({
                    type: StatusMessageType.Fail,
                    msg: "Syncing sleep log failed. Please try again later."
                });
            }

            setIsSyncingLogs(false);     
        }
    }

    function handleSaveEditedSleep(minutesAsleep: number | undefined) {
        const updatedSleepLog = {...sleepLog, sleepDurationInMinutes: minutesAsleep };

        setSleepLog(updatedSleepLog);

        const update = {sleepDurationInMinutes: minutesAsleep === undefined ? null : minutesAsleep};
        sleepLogUpdateFlusher.push(sleepLog.id, update);
    }

    function toggleIsFavorited() {
        if (!editable) {
            return;
        }

        let favorited = false;

        if (sleepLog.favorited) {
            favorited = false;
        }
        else {
            favorited = true;
        }

        const updatedSleepLog = {...sleepLog, favorited: favorited};
        setSleepLog(updatedSleepLog);        

        sleepLogUpdateFlusher.push(sleepLog.id, {favorited: favorited});
    }

    function handleBedtimeChange(bedtime: string) {
        const result = SleepLogModifier.updateBedtime(sleepLog, bedtime);

        if (result.succeeded) {
            setSleepLog(result.sleepLog);

            const update = {bedtime: bedtime ?? null};
            sleepLogUpdateFlusher.push(sleepLog.id, update);
        }                
    }

    const onSaveSleepStages = (sleepSummary: SleepSummary | undefined) => {
        setSleepLog(sleepLog => {
            return { ...sleepLog, sleepSummary: sleepSummary};
        });
    }

    const updateCustomMetric = (index: number, value: string) => {

        // TODO: hacky that this has to be done twice (see code below). Use a service that allows side effects.
        const updatedMetrics = produce(sleepLog.customMetrics, draftMetrics => {
            const metric = draftMetrics![index];
            switch (metric.type) {
                case "0_to_10":
                {
                    const metricValue = parseInt(value);
                    metric.value = isNaN(metricValue) ? undefined : metricValue;
                    break;
                }
                case "number":
                {
                    const metricValue = parseFloat(value);
                    metric.value = isNaN(metricValue) ? undefined : metricValue; 
                    break;
                }             
                default:
                    return;
            }
        });

        const update = {customMetrics: updatedMetrics};
        sleepLogUpdateFlusher.push(sleepLog.id, update);        

        setSleepLog(sleepLog => {
            const metric = sleepLog.customMetrics![index];
            switch (metric.type) {
                case "0_to_10":
                {
                    const metricValue = parseInt(value);
                    metric.value = isNaN(metricValue) ? undefined : metricValue;
                    break;
                }
                case "number":
                {
                    const metricValue = parseFloat(value);
                    metric.value = isNaN(metricValue) ? undefined : metricValue; 
                    break;
                }
                default:
                    return;
            }

        });
    }

    const addCustomMetric = (name: string) => {
        if (!name) {
            return;
        }

        const metric = settings?.customMetrics?.find(metric => metric.name === name);
        if (!metric) {
            return;
        }

        if (sleepLog.customMetrics?.map(m => m.name).includes(name)) {
            addStatus({
                type: StatusMessageType.Fail,
                msg: "A custom value with the same value is already present"
            });
            return;
        }

        // TODO: hacky that this has to be done twice. Use a service that allows side effects.
        const updatedMetrics = produce(sleepLog.customMetrics, draftMetrics => {
            if (!draftMetrics) {
                return [metric];
            }

            draftMetrics.push(metric);
        });

        const update = {customMetrics: updatedMetrics};
        sleepLogUpdateFlusher.push(sleepLog.id, update);

        setSleepLog(sleepLog => {
            if (!sleepLog.customMetrics) {
                sleepLog.customMetrics = [];
            }

            sleepLog.customMetrics.push(metric);
        });
    };

    const deleteCustomMetric = (index: number) => {
        const updatedMetrics = produce(sleepLog.customMetrics, draftMetrics => {
            draftMetrics!.splice(index, 1);
        });

        const update = {customMetrics: updatedMetrics};
        sleepLogUpdateFlusher.push(sleepLog.id, update);

        setSleepLog(sleepLog => {
            sleepLog.customMetrics!.splice(index, 1);
        });
    };

    function formatSleepRange(episode: SleepEpisode) {
        const start = DateTime.fromISO(episode.start).setZone(episode.timezone ?? "utc");
        const end = DateTime.fromISO(episode.end).setZone(episode.timezone ?? "utc");
        return FormatUtils.formatSleepRangeFromDateTime(start, end);
    }

    const rowClass = "gy-1 py-2";
    const titleClass = "text-sm text-muted align-self-center";

    return (
        <Modal 
            centered 
            scrollable={true}
            fullscreen="lg-down" 
            size="lg"
            show={props.show} 
            onHide={closeModal} 
            dialogClassName="sleep-log-modal"
        >

            <Modal.Header>
                <Modal.Title>
                    <span>
                        {formatSleepLogTitleDate(sleepLog.date)}
                        <OverlayTrigger placement={"bottom"} overlay={
                            <Tooltip id={`tooltip-bottom`}>
                                {sleepLog.favorited ? "Remove from favorites" : "Add to favorites"}
                            </Tooltip>}>                 
                            <i className={`bi bi-star${sleepLog.favorited ? "-fill" : ""} text-warning ms-4 favorite-button`} onClick={toggleIsFavorited}></i>    
                        </OverlayTrigger>
                    </span>
                </Modal.Title>
                <button type="button" className="btn-close ms-auto" aria-label="Close" onClick={closeModal}></button>
            </Modal.Header>

            <Modal.Body className="px-3" style={{scrollbarGutter: "stable", scrollbarWidth: "thin"}}>
                
                <Row className="gy-1">
                    <Col xs="12" md="2">
                        <span className={titleClass}>Sleep</span>
                    </Col>
                    <Col xs="auto">
                        <SleepTimeEditor sleepLog={sleepLogView} editable={editable} onSave={handleSaveEditedSleep} />
                    </Col>
                    <Col xs="auto">
                        {sleepLogView.sleepSummary && sleepLogView.sleepSummary.episodes.map((episode, i) => 
                            <React.Fragment key={i}>
                                <span className="text-secondary text-sm">{formatSleepRange(episode)}</span>
                                { i + 1 !== sleepLogView!.sleepSummary!.episodes.length &&
                                    <span className="text-secondary text-sm">, </span>
                                }
                            </React.Fragment>
                        )}
                    </Col>
                    <Col xs="auto">
                        { editable &&
                            <button type="button"
                                className="btn btn-outline-dark py-0 px-1 mx-3 text-sm" 
                                onClick={(e) => setShowSleepEditor(!showSleepEditor)}
                            >
                                {showSleepEditor ? "Hide" : "Editor"}
                            </button>
                        }                      
                    </Col>
                </Row>

                <div>
                    <Collapse in={showSleepEditor}>
                        <div className="my-3">
                            <ErrorBoundary>
                                <SleepStagesEditor
                                    username={loginState.loggedIn ? loginState.username : undefined}
                                    sleepLog={sleepLogView}
                                    onSave={onSaveSleepStages}
                                    addStatus={addStatus}
                                    sleepLogUpdateFlusher={sleepLogUpdateFlusher} 
                                />
                            </ErrorBoundary>
                        </div>
                    </Collapse>
                </div>

                {/* <Row className={rowClass}>
                    <Col xs="2">
                        <span className={titleClass}>Bedtime</span>
                    </Col>
                    <Col>
                            <input type="time"
                                className="text-sm rounded"
                                style={{backgroundColor: "white", "border": "1px dashed #ced4da"}}
                                value={sleepLog.bedtime ?? ""}
                                disabled={!editable}
                                onChange={e => handleBedtimeChange(e.target.value)}
                            >
                            </input>
                    </Col>
                </Row>
                */}

                <Row className="gy-1 py-2">
                    <Col xs="12" md="2">
                        <span className={titleClass}>Rating</span>
                    </Col>
                    <Col xs="2">
                        <select 
                            className="sleep-log-rating sleep-log-select form-select form-select-sm"
                            style={{borderColor: sleepLog.rating !== undefined ? ratingColorMap.get(sleepLog.rating) : ''}}
                            disabled={!editable}
                            value={sleepLog.rating ?? ''}
                            onChange={e => handleRatingChange(e.target.value)}
                        >
                            <option value=""></option>
                            <option value="0">0</option>                        
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option> 
                            <option value="8">8</option> 
                            <option value="9">9</option>
                            <option value="10">10</option>                                                                
                        </select>                        
                    </Col>
                </Row>

                <Row className={rowClass}>
                    <Col md="2">
                        <span className={titleClass}>Feeling</span>
                    </Col>
                    <Col md="10" className="">
                        <TagList
                            type="feelings"
                            tags={sleepLog.feelings ? sleepLog.feelings.map(f => f.feeling) : []}
                            addTag={addFeeling}
                            deleteTag={deleteFeeling}
                            moveTag={moveFeelingTag}
                            addStatus={addStatus}
                            placeholder="+ Feeling"
                            maxTagLength={Limits.MaxTagsLength}
                            tagStyleClasses="ant-tag-purple"
                            editable={editable}
                            showRecent={true}
                        />
                    </Col>            
                </Row>                         

                <Row className={rowClass}>
                    <Col md="2">
                        <span className={titleClass}>
                            Tags
                        </span>
                    </Col>
                    <Col md="10">
                        <TagList
                            type="tags" 
                            tags={sleepLog.tags ?? []} 
                            addTag={addTag} 
                            deleteTag={deleteTag}
                            moveTag={moveTag}
                            addStatus={addStatus}
                            placeholder="+ Tag"
                            maxTagLength={Limits.MaxTagsLength}
                            tagStyleClasses="ant-tag-geek-blue-dark"
                            editable={editable}
                            showRecent={true}
                        />
                    </Col>            
                </Row>

                <Row className="gy-2 py-2">
                    <Col xs="12">
                        <span className={titleClass}>
                            Custom Values
                        </span>
                        <i
                            className={"ms-2 text-muted text-sm" + (areCustomValuesExpanded ? "bi bi-arrows-collapse" : "bi bi-arrows-expand")}
                            style={{cursor: "pointer"}}
                            onClick={() => setAreCustomValuesExpanded(!areCustomValuesExpanded)}
                        >
                        </i>
                    </Col>
                    { areCustomValuesExpanded &&
                        <>
                        { sleepLogView.customMetrics && sleepLogView.customMetrics.map((metric, i) =>
                            <Col xs="12" key={metric.name}>                                    
                                <Row>
                                    <Col xs="4" lg="2">
                                        <div className="text-sm">{metric.name}</div>
                                    </Col>
                                    { metric.type === "0_to_10" &&
                                        <Col xs="4" lg="2">
                                            <select 
                                                className="custom-metric-rating sleep-log-select form-select form-select-sm text-sm"
                                                value={metric.value ?? ""}
                                                disabled={!editable}
                                                onChange={e => updateCustomMetric(i, e.target.value)}
                                            >
                                                <option value=""></option>
                                                <option value="0">0</option>                        
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option> 
                                                <option value="8">8</option> 
                                                <option value="9">9</option>
                                                <option value="10">10</option>                                                                
                                            </select> 
                                        </Col>
                                    }
                                    { metric.type === "number" &&
                                        <Col xs="4" lg="2">
                                            <input type="number" 
                                                className="custom-metric-number form-control text-sm"
                                                value={metric.value ?? ""}
                                                disabled={!editable}
                                                onChange={e => updateCustomMetric(i, e.target.value)}
                                            >
                                            </input>
                                        </Col>
                                    }

                                    <Col xs="1">
                                        { editable && 
                                            <i 
                                                className="bi bi bi-trash3 text-dark text-sm"
                                                style={{cursor: "pointer"}}
                                                onClick={() => deleteCustomMetric(i)}>                            
                                            </i>
                                        }
                                    </Col>
                                </Row>
                            </Col> 
                        )}
                        { editable &&
                            <Col xs="12">
                                <button 
                                    type="button"
                                    className="btn btn-sm btn-outline-primary py-0 me-3 text-sm"
                                    onClick={() => setShowAddMetric(!showAddMetric)}
                                >
                                    +
                                </button>
                                <button 
                                    type="button"
                                    className="btn btn-sm btn-outline-secondary py-0 text-sm"
                                    onClick={openSettings}
                                >
                                    Manage
                                </button>
                            </Col>
                        }
                        { showAddMetric && editable &&
                            <Col xs="12" className="mt-2">
                                <div className="d-flex" style={{gap: ".5rem"}}>
                                    <select 
                                        className='w-auto form-select form-select-sm py-0'
                                        value={newMetricName} 
                                        onChange={e => setNewMetricName(e.target.value)}
                                    >
                                        <option value=""></option>
                                        { settings?.customMetrics && settings.customMetrics.map(metric =>
                                            <option key={metric.name} value={metric.name}>{metric.name}</option>
                                        )}                                            
                                    </select>
                                    <Button
                                        variant="create"
                                        size="sm"
                                        className="text-sm py-0"
                                        onClick={() => addCustomMetric(newMetricName)}
                                    >
                                        Add
                                    </Button>
                                </div>
                            </Col>
                        }
                        </>
                    }
                </Row>

                <Row className="gy-1 py-2">
                    <Col xs="12" md="2">
                        <span className={titleClass}>Events</span>                        
                    </Col>

                    <Col md="10">
                        <TagList
                            type="events" 
                            tags={sleepLog.events?.map(e => e.name) ?? []} 
                            addTag={addEvent} 
                            deleteTag={deleteEvent}
                            moveTag={moveEvent}
                            addStatus={addStatus}
                            placeholder="+ Event"
                            maxTagLength={Limits.MaxTagsLength}
                            tagStyleClasses="ant-tag-lime"
                            editable={editable}
                            showRecent={false}
                        />
                    </Col>                     
                </Row>

                <div className="my-3 border-bottom"></div>

                <Row className="">
                    <Col>
                        <span className={titleClass}>Notes</span>
                        <i
                            className={"ms-2 text-muted text-sm" + (showNotes ? "bi bi-arrows-collapse" : "bi bi-arrows-expand")}
                            style={{cursor: "pointer"}}
                            onClick={() => setShowNotes(!showNotes)}
                        >
                        </i>
                    </Col>
                </Row>                

                { showNotes &&
                <div className="mt-2">
                    { settings?.notesPrompt &&             
                        <Row className="">
                            <Col xs="12">
                                <div className="border rounded p-1 shadow-sm">
                                <div className="text-center">                
                                    <button type="button" className="btn btn-link link-secondary p-0 text-sm" onClick={(e) => setShowPrompt(!showPrompt)}>
                                        {showPrompt ? "Hide" : "Show Prompt"}
                                    </button>
                                </div>
                                {showPrompt &&
                                    <div className="overflow-auto pt-2" style={{whiteSpace: "pre-wrap", maxHeight: "12rem"}}>
                                        {settings.notesPrompt}
                                    </div>
                                }
                                </div>
                            </Col>
                        </Row>
                    }

                    <Row className="mt-2">
                        <Col xs="12">
                            <textarea 
                                className="form-control border-rounded" 
                                rows={notesNumRows} value={sleepLog.notes ?? ""}
                                placeholder="Add note"
                                maxLength={Limits.MaxNotesLength}
                                disabled={!editable} 
                                onChange={e => onNotesChange(e.target.value)} 
                                onFocus={e => setNotesNumRows(10)} 
                                onBlur={e => setNotesNumRows(10)}>
                            </textarea>
                        </Col>
                    </Row>

                    <Row className="mt-1 d-none d-md-block">
                        <Col xs="12" className="text-end text-muted text-sm">
                            {sleepLog.notes?.length ?? 0} / {Limits.MaxNotesLength} characters
                        </Col>
                    </Row>
                </div>    
                }

                <div className="my-3 border-bottom"></div>             

                <Row className="gy-1">
                    <Col>
                        <span className={titleClass}>Sleep Medications</span>
                    </Col>                                             
                </Row>

                <Row className="">
                    <Col>
                        <SelectDrugComponent
                            type="sleepMeds"
                            drugs={sleepLog.medications ?? []} 
                            addDrug={addSleepDrug} 
                            updateDrug={updateSleepDrug} 
                            deleteDrug={deleteSleepDrug} 
                            updateTakenAtTime={changeSleepDrugTakenAt}
                            updateDose={updateSleepDrugDose}
                            tagSuggestionsService={tagSuggestionsService}
                            tagColor="blue"
                            editable={editable}
                        />
                    </Col>                                             
                </Row>

                <Row className="mt-2">
                    <Col>
                        <span className={titleClass}>Other Medications</span>
                    </Col>                                             
                </Row>

                <Row className="">
                    <Col>
                        <SelectDrugComponent
                            type="otherMeds"
                            drugs={sleepLog.otherMedications ?? []} 
                            addDrug={addOtherDrug}
                            updateDrug={updateOtherDrug}
                            deleteDrug={deleteOtherDrug}
                            updateTakenAtTime={changeOtherDrugTakenAt}
                            updateDose={updateOtherDrugDose}
                            tagSuggestionsService={tagSuggestionsService}
                            tagColor="magenta"
                            editable={editable}
                        />
                    </Col>                                             
                </Row>

                <div className="my-3 border-bottom"></div>

                <Row className="mt-3">
                    <Col>
                        <span className={titleClass}>Sleep Data</span>
                    </Col>                                             
                </Row>

                <Nav 
                    variant="tabs" 
                    className="text-sm my-2"
                    activeKey={sleepDataTab}
                    onSelect={(selectedKey) => setSleepDataTab((selectedKey ?? "summary") as any)}
                >
                    { sleepLogView.sleepSummary &&
                        <Nav.Item>
                            <Nav.Link eventKey="summary">Summary</Nav.Link>
                        </Nav.Item>
                    }
                    { sleepLogView.baseSleepLog.fitbit &&
                        <Nav.Item>
                            <Nav.Link eventKey="fitbit">Fitbit</Nav.Link>
                        </Nav.Item>
                    }
                    { sleepLogView.baseSleepLog.withings &&
                        <Nav.Item>
                            <Nav.Link eventKey="withings">Withings</Nav.Link>
                        </Nav.Item>
                    }                                     
                </Nav>

                { sleepDataTab === "summary" && sleepLogView.sleepSummary &&
                    <ErrorBoundary>
                        <Row className="mt-1 gy-2">
                            { sleepLogView.sleepSummary.episodes.map((episode, i) =>
                                    <Col xs="12" lg="6" key={i}>
                                        <SleepStagesCard key={i} episode={episode} source={sleepLogView.sleepSummarySource} />
                                    </Col>                         
                            )}
                        </Row>
                    </ErrorBoundary>
                }

                { sleepDataTab === "fitbit" && sleepLogView.baseSleepLog.fitbit &&
                    <ErrorBoundary>
                        <Row className="mt-1 gy-2">
                            {sleepLogView.baseSleepLog.fitbit.slice().reverse().map((data, i) =>
                                <Col xs="12" lg="6" key={i}>
                                    <FitbitSleepCard key={i} data={data} />
                                </Col>
                            )}
                        </Row>
                    </ErrorBoundary>
                }

                { sleepDataTab === "withings" && sleepLogView.baseSleepLog.withings &&
                    <ErrorBoundary>
                        <Row className="mt-1 gy-2">
                            {sleepLogView.baseSleepLog.withings.episodes.map((data, i) =>
                                <Col xs="12" lg="6" key={i}>
                                    <WithingsSleepStagesCard key={i} 
                                        episode={data} 
                                        sleepStages={sleepLogView.baseSleepLog.withings!.episodeSleepStages[i]}
                                    />
                                </Col>
                            )}
                        </Row>
                    </ErrorBoundary>
                }
                
                <div className="pt-2 d-flex flex-gap-3">
                    <Button
                        variant="outline-primary"
                        size="sm"
                        disabled={isSyncingLogs}
                        onClick={syncSleepLog}
                    >
                        { isSyncingLogs ?
                            <>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span> </span>
                            </>
                            :
                            <>
                                <i className="bi bi-arrow-clockwise"></i>
                                <span>&nbsp;&nbsp;</span>
                            </>
                        }                                     
                        Sync devices
                    </Button>

                    { sleepDataTab !== "summary" &&
                        <>
                        { sleepLogView.primaryConnectedAccount === sleepDataTab ?
                            <button className="btn btn-sm btn-dark" disabled={true}>
                                <i className="bi bi-award-fill"></i> Primary device
                            </button>                            
                            :
                            <button className="btn btn-sm btn-outline-dark" onClick={() => setPrimaryConnectedAccount(sleepDataTab)}>
                                <i className="bi bi-award"></i> Set as primary device
                            </button>
                        }
                        </>
                    }
                </div>

                <div className="py-3">
                    <span className={titleClass}>Summarization</span>
                    <div className="d-flex column-gap-5 py-2">
                        <div>
                            <div className="text-xs text-muted">Sleep - 3 day average</div>
                            <div>
                                { threeDayMovingAverages.hoursSlept !== undefined ?
                                    <span className="">
                                        {Math.floor(threeDayMovingAverages.hoursSlept / 60)}
                                        <span className="text-xs">h</span>
                                        <span> </span>
                                        {Math.floor(threeDayMovingAverages.hoursSlept  % 60)}
                                        <span className="text-xs">m</span>                
                                    </span>
                                    :
                                    <span>―</span>
                                }
                            </div>
                        </div>
                        <div>
                            <div className="text-xs text-muted">Rating - 3 day average</div>
                            <div>
                                { threeDayMovingAverages.rating !== undefined ?
                                    <span>{threeDayMovingAverages.rating.toFixed(1)} <span className="text-xs">/ 10</span></span>
                                    :
                                    <span>―</span>
                                }
                            </div>
                        </div>
                    </div>
                </div>        

                { editable && 
                    <Row className="pt-5 pb-2">                     
                        <Col xs="auto">
                            <Button variant="outline-danger" size="sm" onClick={() => setShowDeleteConfirmation(true)}>
                                <i className="bi bi-trash3"></i> 
                                <span>&nbsp;&nbsp;</span>
                                <span>Delete Log</span>
                            </Button>

                            <DeleteSleepLogDialog show={showDeleteConfirmation} confirm={deleteLog} close={() => setShowDeleteConfirmation(false)} />
                        </Col>                         
                    </Row>
                }

                </Modal.Body>
            <Modal.Footer className="p-1">
                <div className="me-auto">
                    <Button
                        variant="secondary"
                        className="px-4 me-1 btn-sm"
                        onClick={() => changeFocusedSleepLog(-1)}
                    >
                        <i className="bi bi-arrow-left"></i>
                    </Button>
                    <Button 
                        variant="secondary"
                        className="px-4 ms-1 btn-sm"
                        onClick={(e) => changeFocusedSleepLog(1)}
                    >
                        <i className="bi bi-arrow-right"></i>                        
                    </Button>
                    </div>
                <SavingChanges isSaving={props.isSaving} showSpinner={false} />
            </Modal.Footer>
        </Modal>
    );
}

interface SleepTimeEditorProps { 
    sleepLog: ISleepLogView;
    editable: boolean;

    onSave: (minutesAsleep: number | undefined) => void;
};

function SleepTimeEditor({ sleepLog, editable, onSave }: SleepTimeEditorProps) {

    const initialize = (sleepLog: ISleepLogView) => ({
        hours: sleepLog.minutesAsleep !== undefined ? Math.floor(sleepLog.minutesAsleep / 60).toString() : '', 
        minutes: sleepLog.minutesAsleep !== undefined ? Math.round(sleepLog.minutesAsleep % 60).toString() : '',
        editing: false,
        resetToDevice: false
    });

    const [state, setState] = useImmer<{ hours: string, minutes: string, editing: boolean, resetToDevice: boolean }>(() => initialize(sleepLog));

    useEffect(() => {
        setState(initialize(sleepLog));
    }, [sleepLog]);

    const source = useMemo(() => {
        return sleepLog.baseSleepLog.sleepDurationInMinutes === undefined ? sleepLog.sleepSummarySource : "Manually edited";
    }, [sleepLog]);

    function updateHours(e: React.ChangeEvent<HTMLInputElement>) {
        const input = e.target.value;
        const hours = Number(input);

        if (isNaN(hours) || hours >= 100) {
            return;
        }

        setState((state) => {
            state.hours = input;
            state.resetToDevice = false;
        });
    }

    function updateMinutes(e: React.ChangeEvent<HTMLInputElement>) {
        const input = e.target.value;
        const minutes = Number(input);

        if (isNaN(minutes) || minutes >= 60) {
            return;
        }

        setState((state) => {
            state.minutes = input;
            state.resetToDevice = false;
        });
    }

    function reset() {
        let durationInMins = sleepLog.calculateSleepInMins(true /* ignoreOverride */);
        
        setState((state) => {
            state.hours = durationInMins ? Math.floor(durationInMins! / 60).toString() : ''; 
            state.minutes = durationInMins ? Math.floor(durationInMins! % 60).toString() : '';
            state.resetToDevice = true;
        });
    }

    function save() {
        if ((state.hours === '' && state.minutes === '') || state.resetToDevice) {
            onSave(undefined);

            setState(state => {
                state.editing = false;
            });            

            return;
        }

        const hours = Number(state.hours);
        const minutes = Number(state.minutes);

        if (isNaN(hours) || isNaN(minutes) || hours < 0 || minutes < 0) {
            return;
        }

        const minutesAsleep = hours * 60 + minutes;
        setState(state => {
            state.editing = false;
        });

        onSave(minutesAsleep);
    }

    function cancel() {
        setState(initialize(sleepLog));
    }

    return (
        <>
        { editable && state.editing ?
            <div className="d-flex" style={{gap: "1rem"}}>
                <div>
                    <input type="number"
                        className="underline-input text-sm"
                        style={{width: "2rem"}}
                        value={state.hours}
                        min={0}
                        max={100}
                        onChange={updateHours}
                    >
                    </input>

                    <span> : </span>

                    <input type="number"
                        className="underline-input text-sm" 
                        style={{width: "2rem"}}
                        value={state.minutes}
                        min={0}
                        max={60}
                        onChange={updateMinutes}
                    >
                    </input>
                </div>
                <div>
                    <SimpleTooltip message="Save">
                        <button type="button" className="border-0 bg-white fs-5" onClick={() => save()}>
                            <i className="bi bi-check-circle"></i>
                        </button>
                    </SimpleTooltip>
                    <SimpleTooltip message="Cancel">
                        <button type="button" className="border-0 bg-white fs-5" onClick={() => cancel()}>
                            <i className="bi bi-x-circle"></i>
                        </button>
                    </SimpleTooltip>
                    <SimpleTooltip message="Reset to device or manually entered episodes">
                        <button type="button" className="border-0 bg-white fs-5" onClick={() => reset()}>
                            <i className="bi bi-arrow-clockwise"></i>
                        </button> 
                    </SimpleTooltip>                                         
                </div>
            </div>
        :
            <div className="d-flex" style={{gap: ".5rem"}}>
                { sleepLog.minutesAsleep !== undefined ?
                    <div className="d-flex">
                        <SimpleTooltip message={`Source: ${source}`}>
                            <span className="">
                                {Math.floor(sleepLog.minutesAsleep / 60)}
                                <span className="text-xs">h</span>
                                <span> </span>
                                {Math.floor(sleepLog.minutesAsleep % 60)}
                                <span className="text-xs">m</span>                
                            </span>
                        </SimpleTooltip>
                    </div>

                    :

                    <div>
                        ―
                    </div>
                }
                { editable &&
                    <div>
                        <button type="button" 
                            className="border-0 bg-white" 
                            onClick={() => setState(state => { state.editing = true; })}
                        >
                            <i className="bi bi-pencil"></i>
                        </button>
                    </div>
                }             
            </div>
        }
        </>
    );
}