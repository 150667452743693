import { useMemo, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import StringUtils from "../../../../utils/StringUtils";
import { SortedTags } from "./SortedTagCountChart";
import { TailwindColors } from "../../../../constants/tailwindColors";
import SimpleTooltip from "../../../sleep-log-view/common/SimpleTooltip";

interface Props {
    chartTitle?: string
    sortedTags: SortedTags;
    numDays: number;
    progressBarColor: string;
    backgroundColor?: string;
    limit?: number;
}

export default function SortedTagsChartV2(props: Props) {

    const [showMore, setShowMore] = useState(false);
    const [keyword, setKeyWord] = useState('');
    const limit = props.limit ?? 10;

    const filteredTags = useMemo(() =>
        props.sortedTags.filter(tag => StringUtils.containsCaseInsensitive(tag.name, keyword))
    , [props.sortedTags, keyword]);

    const visibleTags = useMemo(() => 
        filteredTags.slice(0, showMore ? undefined : limit)
    , [filteredTags, limit, showMore]);

    function handleShowMore() {
        setShowMore(!showMore);
    } 
    
    function formatPercent(count: number) {
        const percent = props.numDays > 0 ? Math.round((count / props.numDays) * 100) : 0;
        return percent;
    }

    return (
        <div>
            { props.chartTitle &&
                <div className="text-center mb-3 fs-5">{props.chartTitle}</div>
            }

            <div className="overflow-auto px-2 px-lg-5" style={{maxHeight: "200px"}}>
                { visibleTags.map((tag, i) =>
                    <div>
                        <div className="d-flex pt-3 pb-1 text-sm justify-content-between" key={tag.name}>
                            <span className="text-truncate">{tag.name}</span>
                            <span className="text-xs text-muted">{tag.count} days <span className="fw-bold">({formatPercent(tag.count)}%)</span></span>
                        </div>
                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={`tooltip-${'top'}`}>
                                    <span>{tag.count}/{props.numDays} days ({formatPercent(tag.count)}%)</span>
                                </Tooltip>
                            }
                        >
                            <div 
                                className="progress align-self-center" 
                                style={{
                                    ["--bs-progress-bar-bg" as any]: props.progressBarColor,
                                    ["--bs-progress-bg" as any]: props.backgroundColor,
                                    ["--bs-progress-height" as any]: "8px",
                                    ["--bs-progress-border-radius" as any]: "999px"
                                }}
                            >
                                <div className="progress-bar" role="progressbar"
                                    style={{width: `${((tag.count / props.numDays) * 100).toFixed(0)}%`}}>
                                </div>
                            </div>
                        </OverlayTrigger>
                    </div>                  
                )}
            </div>

            <div className="text-center mt-1">
                <button type="button" className="btn btn-link link-secondary text-xxs" onClick={handleShowMore}>
                    {showMore ? "Show less" : "Show more"}
                </button>
            </div>

            { false &&
                <div>
                    <div className="py-3"></div>
                    <TagProportionChart tags={filteredTags} numDays={props.numDays} />
                </div>
            }

            <div className="d-flex justify-content-center mt-1">
                <div className="p-1">
                    <i className="bi bi-search mt-1"></i>
                </div>
                <input type="text"
                    className="form-control form-control-sm w-50"
                    placeholder="Find"
                    value={keyword}
                    onChange={e => setKeyWord(e.target.value)}
                >
                </input>
            </div>           
        </div>
    );
}

interface Props2 {
    tags: SortedTags;
    numDays: number;
}

function toPercentage(numerator: number, denominator: number) {
    let percent = ((numerator / denominator) * 100);
    return percent;
}

function toPercentageString(numerator: number, denominator: number, truncate?: number) {
    let percent = toPercentage(numerator, denominator).toFixed(truncate);
    return `${percent}%`;
}

function TagProportionChart({ tags, numDays }: Props2) {
    const uncoveredDays = useMemo(
        () => numDays - tags.map(tag => tag.count).reduce((lhs, rhs) => lhs + rhs, 0),
        [tags, numDays]
    );

    const partitions = useMemo(() =>
        uncoveredDays > 0 ? [...tags, { name: "N/A", count: uncoveredDays }] : tags
    , [tags, uncoveredDays]);

    const bgColors = useMemo(() => {
        return [
            TailwindColors["amber-200"],
            TailwindColors["indigo-200"],

            TailwindColors["red-200"],
            TailwindColors["green-200"],
            TailwindColors["blue-200"],
            TailwindColors["fuchsia-200"],
            TailwindColors["cyan-200"],
            TailwindColors["rose-200"],
            TailwindColors["stone-200"]
        ]
    }, []); 

    return (
    <>
        { uncoveredDays >= 0 &&
            <figure className="px-5">
                <div className="d-flex border border-2 rounded" style={{ minHeight: "48px", ["--bs-border-color" as any]: TailwindColors["slate-200"]}}>
                    { partitions.map((tag, i) =>
                        <SimpleTooltip message={`${tag.name} ${toPercentageString(tag.count, numDays, 0)}`} position="bottom">
                            <div
                                className="d-flex flex-column justify-content-center align-items-center text-truncate p-2"
                                style={{
                                    width: toPercentageString(tag.count, numDays),
                                    backgroundColor: bgColors[i % bgColors.length],
                                }}
                            >
                                { toPercentage(tag.count, numDays) > 10 &&
                                    <>
                                        <div className="fw-semibold">{ toPercentageString(tag.count, numDays, 0) }</div>
                                        <div className="text-xs text-muted">{tag.name}</div>
                                    </>
                                }
                            </div>
                        </SimpleTooltip>
                    )}
                </div>
            </figure>
        }
    </>
    )
}