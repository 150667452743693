import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import { SleepEpisode, SleepStage } from "../../types/SleepLog";
import { formatTimeFromComponents, FormatUtils } from "../../utils/FormatUtility";
import SimpleTooltip from "./common/SimpleTooltip";
import TimeDuration from "./common/TimeDuration";
import SleepStagesChart from "./SleepStagesChart";

interface Props {
    episode: SleepEpisode;
    onSelect: (stage: SleepStage) => void;
}

export default function SleepStagesEditorCard({episode, onSelect}: Props) {

    function formatSleepRange(date: string) {
        const datetime = DateTime.fromISO(date).setZone(episode.timezone ?? "utc");                
        return formatTimeFromComponents(datetime.hour, datetime.minute);
    }

    const [zoomPercent, setZoomPercent] = useState(100);

    const startToolTip = useMemo(() => {
        return DateTime.fromISO(episode.start, { zone: episode.timezone ?? "utc" }).toLocaleString(DateTime.DATETIME_FULL);
    }, [episode.start, episode.timezone]);

    const endToolTip = useMemo(() => {
        return DateTime.fromISO(episode.end, { zone: episode.timezone ?? "utc" }).toLocaleString(DateTime.DATETIME_FULL);
    }, [episode.end, episode.timezone]);
    
    function onZoom(zoom: "in" | "out") {
        setZoomPercent(prevZoomPercent => {
            const delta = zoom == "in" ? 25 : -25;
            const newZoomPercent = prevZoomPercent + delta;
            return newZoomPercent;
        });
    }

    return (
        <div className="" id="SleepStagesEditorCard">
            <div className="overflow-x-auto">
                <div className="pb-1" style={{width: `${zoomPercent}%`}}>
                    <div className="d-flex text-xs justify-content-between">
                        <div>
                            <SimpleTooltip message={startToolTip}>
                                <div>
                                    {formatSleepRange(episode.start)}
                                </div>
                            </SimpleTooltip>
                        </div>
                        <div>
                            <SimpleTooltip message={endToolTip}>
                                <div>{formatSleepRange(episode.end)}</div>
                            </SimpleTooltip>
                        </div>    
                    </div>     
                    <div className="mt-1">
                        <SleepStagesChart
                            episode={episode}
                            onSelect={onSelect}
                            height="10px" 
                        />
                    </div>
                </div>
            </div>

            <div className="d-flex justify-content-end pt-2">
                <button className="btn btn-sm text-muted" onClick={() => onZoom("in")} disabled={zoomPercent >= 300}>
                    <i className="bi bi-zoom-in"></i>
                </button>
                <button className="btn btn-sm text-muted" onClick={() => onZoom("out")} disabled={zoomPercent <= 100 }>
                    <i className="bi bi-zoom-out"></i>
                </button>                   
            </div>

            <div className="">
                <div className="mt-1 text-xs">
                    <TimeDuration duration={episode.minutesAsleep + episode.minutesAwake}/>
                    <span className="text-muted"> in bed</span>
                </div>                            
                <div className="mt-1 d-flex">
                    <div className="progress bg-white" style={{height: "1.25rem", width: (50 * (episode.minutesAsleep / (episode.minutesAwake + episode.minutesAsleep))).toString() + "%"}}>
                        <div className="progress-bar" role="progressbar"
                            style={{width: "100%", backgroundColor: '#6ea8fe'}}>
                        </div>                 
                    </div>
                    <div className="ms-1 text-xs">
                            <TimeDuration duration={episode.minutesAsleep}/>
                            <span className="text-muted"> asleep</span>
                        </div>    
                </div>
                <div className="mt-1 d-flex">
                    <div className="progress bg-white" style={{height: "1.25rem", width: (50 * (episode.minutesAwake / (episode.minutesAwake + episode.minutesAsleep))).toString() + "%"}}>
                        <div className="progress-bar" role="progressbar"
                            style={{width: "100%", backgroundColor: '#e35d6a'}}>
                        </div>                            
                    </div>
                    <div className="ms-1 text-xs">
                        <TimeDuration duration={episode.minutesAwake}/>
                        <span className="text-muted"> awake</span>
                    </div>
                </div>                 
            </div>
        </div>                                                                                            
    ); 
}