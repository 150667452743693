import { DateTime } from "luxon";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { SleepEpisode, SleepStage } from "../../types/SleepLog";
import { FormatUtils } from "../../utils/FormatUtility";

interface PropTypes {
    episode: SleepEpisode;
    height?: string;
    onSelect: (stage: SleepStage) => void;
}

export default function SleepStagesChart({
    episode,
    height,
    onSelect
}: PropTypes) {

    const timezone = episode.timezone ?? 'UTC';

    function getWidth(stage: SleepStage) {
        const totalTimeInSeconds = (episode.minutesAsleep + episode.minutesAwake) * 60;
        return `${((stage.seconds / totalTimeInSeconds) * 100)}%`;        
    }

    function getColor(stage: SleepStage) {
        switch (stage.type) {
            case 'awake':
                return '#e35d6a';
            case 'asleep':
                return '#69b1ff';                
            case 'light': 
                return '#6ea8fe';
            case 'rem':
                return '#cfe2ff';
            case 'deep':
                return '#0a58ca';
        }
    }

    function getTimeRange(stage: SleepStage) {
        const start = DateTime.fromISO(stage.start).setZone(timezone);
        const end = DateTime.fromISO(stage.start).setZone(timezone).plus({ seconds: stage.seconds});
        return FormatUtils.formatSleepRangeFromDateTime(start, end);
    }
    
    return (
        <div className="progress bg-white position-relative" style={{height: height ?? "10px"}}>
            {episode.sleepStages.map((s, i) =>
                <OverlayTrigger
                    key={i}
                    placement={'top'}
                    trigger={["hover", "focus"]}
                    offset={[0, 6]}
                    overlay={
                        <Tooltip id={i.toString()}>
                            <div>
                                <span>{`${s.type.substring(0, 1).toUpperCase()}${s.type.substring(1)}`} </span>

                                { s.seconds >= (60 * 60) &&
                                    <span>{Math.floor(s.seconds / 60 / 60)}h </span>
                                }

                                <span>{`${Math.round((s.seconds / 60) % 60)}m`}</span>
                            </div>

                            <div>{getTimeRange(s)}</div>
                        </Tooltip>
                    }
                >
                    <div className='progress-bar position-relative'
                        role="progressbar"
                        style={{
                            width: getWidth(s),
                            backgroundColor: getColor(s),
                            cursor: "pointer"
                        }}
                        onClick={() => onSelect(s)}>
                    </div>
                </OverlayTrigger>
            )}
        </div>
    );
}