import { useEffect, useMemo, useState } from "react";

import { ChartArea, ChartData, ChartOptions, ChartType } from "chart.js";
import 'chartjs-adapter-luxon';
import { Bar, Line } from "react-chartjs-2";
import ChartDeferred from 'chartjs-plugin-deferred';


import ISleepLogView from "../../../../types/ISleepLogView";
import { FormatUtils } from "../../../../utils/FormatUtility";
import { Aggregation } from "../../../../types/types";
import AnalyticsUtils from "../../../../utils/analytics-utils";
import _ from "lodash";
import { ChartStyles } from "../constants/chart-styles";

interface Props {
    sleepLogGroups: ISleepLogView[][];
    aggregationPeriod: Aggregation;
    type: 'bar' | 'line';
    showLines?: boolean;
    useColorCoding?: boolean;
    enableZoom?: boolean;
    alwaysShowPoints?: boolean;
}

const aggregationPeriodMap: Record<Aggregation, number> = {
    'day': 1,
    'week': 7,
    'month': 30,
    'quarter': 91,
    'threeDayMoving': 3,
    'sevenDayMoving': 7,
    'fourteenDayMoving': 14
}

type ChartProps = Omit<Props, "type">;

export default function RatingPerDayChart({type, ...props}: Props) {
    return (
        <div>
            { type === 'bar' &&
                <RatingPerDayBarChart
                    {...props}               
                />
            }
            { type === 'line' &&
                <RatingPerDayLineChart
                    {...props}
                />
            }            
        </div>        
    );
}

function RatingPerDayBarChart({
    sleepLogGroups,
    aggregationPeriod,
    showLines,
    useColorCoding,
    enableZoom,
}: ChartProps) {

    const [data, setData] = useState<ChartData<"bar", number[], string>>({
        labels: [],
        datasets: []
    });
    
    const options: ChartOptions<"bar"> = useMemo(() => 
        getChartOptions(enableZoom ?? false, showLines ?? false, aggregationPeriod),
        [enableZoom, showLines, aggregationPeriod]);

    useEffect(() => {
        const data = isAggregation(aggregationPeriod) ? 
            getAggregationAverage(sleepLogGroups.slice(0, 1), aggregationPeriod, useColorCoding ?? false, "bar", false) : 
            getRatingData(sleepLogGroups, aggregationPeriod, useColorCoding ?? false, "bar", false);

        setData(data);

    }, [sleepLogGroups[0], sleepLogGroups[1], aggregationPeriod, useColorCoding]);
    
    return (
        <div>
            <Bar
                data={data}
                options={options}
                plugins={[ChartDeferred]}
            />
        </div>
    );
}

function RatingPerDayLineChart({
    sleepLogGroups,
    aggregationPeriod,
    showLines,
    useColorCoding: colorRows,
    enableZoom,
    alwaysShowPoints
}: ChartProps) {

    const [data, setData] = useState<ChartData<"line", number[], string>>({
        labels: [],
        datasets: []
    });
    
    const options: ChartOptions<"line"> = useMemo(() => getChartOptions(
        enableZoom ?? false,
        showLines ?? false,
        aggregationPeriod),
        [enableZoom, showLines, aggregationPeriod]);

    useEffect(() => {
        const data = isAggregation(aggregationPeriod) ?
            getAggregationAverage(sleepLogGroups.slice(0, 1), aggregationPeriod, colorRows ?? false, "line", !!alwaysShowPoints) :
            getRatingData(sleepLogGroups, aggregationPeriod, colorRows ?? false, "line", !!alwaysShowPoints);
        setData(data);

    }, [sleepLogGroups[0], sleepLogGroups[1], aggregationPeriod, colorRows, alwaysShowPoints]);
    
    return (
        <div>
            <Line
                data={data}
                options={options}
                plugins={[ChartDeferred]}
            />       
        </div>        
    );
}

function isAggregation(aggregation: Aggregation): aggregation is 'week' | 'month' {
    return aggregation === "week" || aggregation === "month";
}

function getChartOptions<T extends ChartType>(enableZoom: boolean, showLines: boolean, aggregationPeriod: Aggregation): ChartOptions<T> {
    const useTime = !isAggregation(aggregationPeriod);

    const timeType = {
        type: 'time',
        time: {
            unit: 'day',
            displayFormats: {
                day: 'M/d'
            },
            tooltipFormat: "MM/dd/y"
        },   
        adapters: {
            date: {
                zone: "UTC"
            }
        }, 
    };

    return {
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: false
            },
            zoom: {
                pan: {
                    enabled: enableZoom,
                    mode: 'x',
                },
                zoom: {
                    wheel: {
                        enabled: enableZoom,
                    },
                    pinch: {
                        enabled: enableZoom
                    },
                    mode: 'x',
                }
            },
        },
        scales: {
            x: {
                ...(useTime && timeType as any),
                grid: {
                    display: false,
                },
                ticks: {
                    display: true,
                    font: {
                        ...ChartStyles.axisFont
                    }
                },
            },
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 1,
                    font: {
                        ...ChartStyles.axisFont
                    },
                    display: false
                },
                grid: {
                    ...ChartStyles.gridYAxis,  
                    display: showLines ?? true,          
                },
                position: 'right'
            }
        },
    } as unknown as ChartOptions<T>;    
}


function getAggregationAverage(
    sleepLogsGroups: ISleepLogView[][],
    aggregationPeriod: 'week' | 'month',
    colorRows: boolean,
    type: 'line' | 'bar',
    alwaysShowPoints: boolean
) {
    let dataset: any;
    
    if (type === 'line') {
        const pointCount = sleepLogsGroups.map(group => group.length).reduce((prev, cur) => prev + cur, 0);
        const hasFilter = sleepLogsGroups.length > 1;

        dataset = {
            label: ["Rating"],
            data: [],
            borderJoinStyle: "bevel",
            borderColor: function(context: any) {
                if (!colorRows) {
                    return primaryBgColor;
                }

                const chart = context.chart;

                const yMax = chart.scales.y.max;
                const {ctx, chartArea} = chart;
        
                if (!chartArea) {
                    // This case happens on initial chart load
                    return undefined;
                }

                return getGradient(ctx, chartArea, yMax, .8);
            },
            borderWidth: 2.5,
            pointRadius: (pointCount / aggregationPeriodMap[aggregationPeriod]) > 45 && !alwaysShowPoints ? 0 : 2.5,
            pointBorderWidth: 1,
            pointHoverBorderWidth: 2,
            pointBackgroundColor: pointCount <= 1 ? getBorderColor(colorRows, 0) : getBorderColor(colorRows, 1),
            pointBorderColor: pointCount <= 1 && !hasFilter ? getBorderColor(colorRows, 0) : getBorderColor(colorRows, 1),
            pointHoverBorderColor: getBorderColor(colorRows, 1),
            backgroundColor: (context: any) => {
                const { ctx, chartArea } = context.chart;
                var gradient = ctx.createLinearGradient(0, 0, 0, chartArea.height);
                gradient.addColorStop(0, getPrimaryColor(.25));
                gradient.addColorStop(.33, getPrimaryColor(.2));
                gradient.addColorStop(.66, getPrimaryColor(.05));
                gradient.addColorStop(1, 'rgba(255, 255, 255,0)');

                return gradient;
            },
            fill: true,             
            tension: .2      
        };
    }
    else {
        dataset = {
            label: ["Rating"],
            data: [],
            fill: true,
            backgroundColor: [],
            borderColor: [],
            borderWidth: 0,
            maxBarThickness: 100
        }; 
    }

    const data = {
        labels: [] as string[],
        datasets: [dataset]
    };

    if (sleepLogsGroups.length === 0) {
        return data;
    }

    let totalLogs = sleepLogsGroups.map(logs => logs.length).reduce((prev, cur) => prev + cur);

    if (totalLogs === 0) {
        return data;
    }

    let start = "9999-12-31";
    let end = "";

    for (const sleepLogs of sleepLogsGroups) {
        if (sleepLogs.length > 0) {
            const firstDate = sleepLogs[0].date.asString;
            const lastDate = sleepLogs[sleepLogs.length - 1].date.asString;

            start = firstDate < start ? firstDate : start;
            end = lastDate > end ? lastDate : end;
        }
    };

    const dateToRatingMap = Object.fromEntries(sleepLogsGroups[0].map(sleepLog => [sleepLog.date.asString, sleepLog.rating]));
    
    const dateRange = AnalyticsUtils.fillDateRange(start, end);
    const values: (number | undefined)[] = dateRange.map(date => dateToRatingMap[date]);

    const { dates, values: aggregatedValues } = AnalyticsUtils.aggregation(
        dateRange,
        values,
        aggregationPeriod,
        1
    );

    for (const [date, value] of _.zip(dates, aggregatedValues)) {
        const formattedDate = date ? (FormatUtils.formatDate(date, true /* omitYear */) + "-") : '';
        data.labels.push(formattedDate);
        dataset.data.push(value as number);

        if (type === 'bar') {
            dataset.backgroundColor.push(colorRows ? getRatingBasedColor(value ?? 0, .8) : primaryBgColor);
            dataset.borderColor.push(colorRows ? getRatingBasedColor(value ?? 0, 1) : primaryBorderColor);
        }
    }    

    return data;
}    

function getRatingData(
    sleepLogsGroups: ISleepLogView[][],
    aggregationPeriod: Aggregation,
    colorRows: boolean,
    type: 'line' | 'bar',
    alwaysShowPoints: boolean
) {
    const hasFilter = sleepLogsGroups.length > 1;

    let dataset: any;    

    if (type === 'line') {
        const pointCount = sleepLogsGroups.map(group => group.length).reduce((prev, cur) => prev + cur, 0);

        dataset = {
            label: ["Rating"],
            data: [],
            borderJoinStyle: "bevel",
            borderColor: getBorderColor(colorRows, sleepLogsGroups.length <= 1 ? .8 : .25),
            borderWidth:  sleepLogsGroups.map(group => group.length).reduce((prev, cur) => prev + cur, 0) > 180 && aggregationPeriod === "day" ? 2 : 2.5,
            pointRadius: [],
            pointBorderWidth: 1,
            pointHoverBorderWidth: 2,
            pointBackgroundColor: (pointCount > 45 && !hasFilter && !alwaysShowPoints) ? getBorderColor(colorRows, 0) : getBorderColor(colorRows, 1),
            pointBorderColor: (pointCount > 45 && !hasFilter && !alwaysShowPoints) ? getBorderColor(colorRows, 0) : getBorderColor(colorRows, 1),
            pointHoverBorderColor: getBorderColor(colorRows, 1),
            tension: .2,
            backgroundColor: (context: any) => {
                const { ctx, chartArea } = context.chart;
                var gradient = ctx.createLinearGradient(0, 0, 0, chartArea.height);
                gradient.addColorStop(0, getPrimaryColor(.25));
                gradient.addColorStop(.33, getPrimaryColor(.2));
                gradient.addColorStop(.66, getPrimaryColor(.05));
                gradient.addColorStop(1, 'rgba(255, 255, 255,0)');

                return gradient;
            },
            fill: true,            
        };
    }
    else {
        dataset = {
            label: ["Rating"],
            data: [],
            fill: true,
            backgroundColor: [],
            borderColor: [],
            borderWidth: 0,
            maxBarThickness: 100
        }; 
    }

    const data: any = {
        labels: [],
        datasets: [
            dataset
        ],
    };    

    let datesMap: {[key: string]: {rating: number | undefined, datasetIndex: number}} = {};        

    sleepLogsGroups.forEach((sleepLogs, i) => {
        sleepLogs.forEach((log) => {

            datesMap[log.date.asString] = {
                rating: log.rating,
                datasetIndex: i
            };
        });
    });

    const dates = Object.keys(datesMap).sort();

    if (dates.length === 0) {
        return data;
    }

    const dateRange = AnalyticsUtils.fillDateRange(dates[0], dates[dates.length - 1]);
    const values: (number | undefined)[] = dateRange.map(date => datesMap[date]?.rating);
    const windowSize = aggregationPeriodMap[aggregationPeriod];
    const movingAverages = AnalyticsUtils.movingAverage(dateRange, values, windowSize, 1);

    data.labels = dateRange;

    for (const [date, value] of _.zip(dateRange, movingAverages)) {
        dataset.data.push(value as number);
        const metadata = datesMap[date!];

        let bgColor = '';
        let borderColor = '';
        let pointRadius: number = 0;

        if (metadata && value !== undefined) {
            bgColor = colorRows ? (metadata.datasetIndex == 0 ? getRatingBasedColor(value ?? 0, .8) : getRatingBasedColor(value ?? 0, .15)) : (metadata.datasetIndex === 0 ? primaryBgColor : filteredBgColor);
            borderColor = colorRows ? (metadata.datasetIndex == 0 ? getRatingBasedColor(value ?? 0, 1) : getRatingBasedColor(value ?? 0, .15)) : (metadata.datasetIndex === 0 ? primaryBorderColor : filteredBorderColor);
            pointRadius = metadata.datasetIndex === 0 ? 2.5 : 0 ;
        }

        if (type === 'bar') {
            dataset.backgroundColor.push(bgColor);
            dataset.borderColor.push(borderColor);
        }

        if (type === 'line') {
            dataset.pointRadius.push(pointRadius);
        }
    }    
    
    return data;
}

const getBorderColor = (colorCodeRows: boolean, opacity: number) => {
    const scriptableBorderColor = (context: any) => {
        if (!colorCodeRows) {
            return getPrimaryColor(opacity);
        }

        const chart = context.chart;

        const yMax = chart.scales.y.max;
        const {ctx, chartArea} = chart;

        if (!chartArea) {
            // This case happens on initial chart load
            return undefined;
        }

        return getGradient(ctx, chartArea, yMax, opacity);
    }

    return scriptableBorderColor;
};

const getDaysInMonth = function(month: number, year: number) {
    return new Date(year, month + 1, 0).getUTCDate();
};   


const ratingColors = [
    'rgba(255, 99, 132, X)',
    'rgba(250, 140, 22, X)',
    'rgba(255, 197, 61, X)',
    'rgba(250, 219, 20, X)',
    'rgba(75, 192, 192, X)'
];    

function getRatingBasedColor(rating: number, opacity: number) {
    if (rating >= 7) {
        return ratingColors[4].replace('X', opacity.toString());
    }
    else if (rating >= 5) {
        return ratingColors[3].replace('X', opacity.toString());
    }
    else if (rating >= 4) {
        return ratingColors[2].replace('X', opacity.toString());
    }        
    else if (rating >= 3) {
        return ratingColors[1].replace('X', opacity.toString());
    }
    else {
        return ratingColors[0].replace('X', opacity.toString());
    }        
}

function getPrimaryColor(opacity: number) {
    return `rgba(251, 191, 36, ${opacity})`;
}

const primaryBgColor = getPrimaryColor(.75);
const primaryBorderColor = getPrimaryColor(0);

const filteredBgColor = getPrimaryColor(.15);
const filteredBorderColor = getPrimaryColor(.15);

function mapColor(color: 'red' | 'darkOrange' | 'orange' | 'yellow' | 'lightYellow' | 'green' | 'darkGreen', opacity = 1) {
    const colors = {
        red: 'rgba(245, 34, 45, X)',
        darkOrange: 'rgba(250, 140, 22, X)',
        orange: 'rgba(255, 192, 105, X)',
        yellow: 'rgba(250, 219, 20, X)',
        lightYellow: 'rgba(250, 219, 20, X)',
        green: 'rgba(149, 222, 100, X)',
        darkGreen: 'rgba(56, 158, 13, X)',
    };

    return colors[color].replace('X', opacity.toString());
}

function getGradient(ctx: CanvasRenderingContext2D, chartArea: ChartArea, yMax: number, opacity: number) {
    let width, height, gradient;

    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (gradient === undefined || gradient === null || width !== chartWidth || height !== chartHeight) {
        // Create the gradient because this is either the first render
        // or the size of the chart has changed
        width = chartWidth;
        height = chartHeight;
        gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        
        gradient.addColorStop(0, mapColor('red', opacity));
        if (yMax > 2.9) gradient.addColorStop(Math.min(1, 2.5 / yMax), mapColor('red', opacity));

        if (yMax > 3) gradient.addColorStop(Math.min(1, 3 / yMax), mapColor('darkOrange', opacity));
        if (yMax > 3.8) gradient.addColorStop(Math.min(1, 3.9 / yMax), mapColor('darkOrange', opacity));

        if (yMax > 4) gradient.addColorStop(Math.min(1, 4 / yMax), mapColor('orange', opacity));
        if (yMax > 4.5) gradient.addColorStop(Math.min(1, 4.5 / yMax), mapColor('orange', opacity));

        if (yMax > 5) gradient.addColorStop(Math.min(1, 5 / yMax), mapColor('yellow', opacity));
        
        if (yMax > 6) gradient.addColorStop(Math.min(1, 6 / yMax), mapColor('lightYellow', opacity));
        if (yMax > 6.9) gradient.addColorStop(Math.min(1, 6.9 / yMax), mapColor('lightYellow', opacity));
        
        if (yMax > 8) gradient.addColorStop(Math.min(1, 8 / yMax), mapColor('green', opacity));
        
        if (yMax > 9) gradient.addColorStop(Math.min(1, 9 / yMax), mapColor('darkGreen', opacity)); 
    }
    
  return gradient;
}