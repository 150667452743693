import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Overlay } from "react-bootstrap";
import { TagType } from "../../services/TagSuggestionsService";
import { TagStyle } from "../../types/SleepLogSettings";
import { useImmer } from "use-immer";
import useStore from "../../shared/store/useStoreService.hook";
import SleepLogSettingsService from "../sleep-log-settings/services/SleepLogSettingsService";
import { SleepLogSettingsServiceContext } from "../sleep-log-settings/services/sleep-log-settings-service.provider";
import { CustomTagStylesModal } from "../sleep-log-settings/components/CustomTagStylesModal";
import _ from "lodash";
import { defaultStyles } from "../../constants/CustomTagStyles";
import { Tag, TagAction } from "./Tag";
import { StatusMessage, StatusMessageType } from "../common/StatusMessages";
import { useTagSuggestionsService } from "../../services/TagSuggestionsService.provider";
import styles from "./styles/tag-list.module.css";

interface TagsComponentProps {
    type: Extract<TagType, "tags" | "feelings" | "events">;
    tags: string[];
    placeholder: string;
    tagStyleClasses: string;
    maxTagLength: number;
    editable: boolean;
    showRecent: boolean;

    addTag: (tag: string) => boolean;
    deleteTag: (index: number, tag: string) => boolean;
    moveTag: (sourceIndex: number, targetIndex: number) => void;
    addStatus: (msg: StatusMessage) => void;
}

interface TagState {
    input: string;
    showSuggestions: boolean;
    suggestions: string[] | undefined;
    pos: number;
    showRecent: boolean;
    recent: string[] | undefined;
}

export default function TagList({
    type,
    tags,
    addTag,
    deleteTag,
    moveTag,
    addStatus,
    placeholder,
    tagStyleClasses,
    maxTagLength,
    editable,
    showRecent
}: TagsComponentProps) {

    const [sleepLogSettingsService, sleepLogSettings] = useStore<SleepLogSettingsService, SleepLogSettingsService["state"]>(SleepLogSettingsServiceContext);
    const [tagSuggestionsService, cachedSuggestions] = useTagSuggestionsService();
    
    const [tagState, setTagState] = useState<TagState>(() => ({
        input: "",
        showSuggestions: false,
        suggestions: undefined,
        pos: -1,
        showRecent: false,
        recent: undefined
    }));

    const [styleModalState, setStyleModalState] = useImmer(() => ({
        show: false,
        tagName: "",
        style: defaultStyles[type],
    }));
    
    const triggerRef = useRef<HTMLInputElement>(null);

    const [dragItem, setDragItem] = useState<number>();
    const [dragOverItem, setDragOverItem] = useState<number>();    

    const dragStart = (e: React.DragEvent, index: number) => {
        if (!editable) {
            return;
        }

        setDragItem(index);
    };
     
    const dragEnter = (e: React.DragEvent, index: number) => {
        setDragOverItem(index);
    };    

    const drop = () => {
        if (dragItem !== undefined && dragOverItem !== undefined) {
            moveTag(dragItem, dragOverItem);
            setDragItem(undefined);
            setDragOverItem(undefined);        
        }
    };

    useEffect(() => {
        setTagState(prevState => {
            const threshold = .0000000001; // Greater than zero so that default tags aren't shown.
            let recentTags = tagSuggestionsService.filter(type, '', 100, threshold);
            recentTags = recentTags?.filter(tag => !tags.includes(tag));

            let suggestions = matchingSuggestions(prevState.input ?? []);

            return {...prevState, recent: recentTags, suggestions: suggestions };
        });
    }, [cachedSuggestions, tags])

    function handleKeyPress(event: any) {
        if (event.key === "Enter") {
            return;
        }
        else if (event.key === "ArrowDown") {
            event.preventDefault();

            if (tagState.suggestions === undefined) {
                return;
            }

            if (!tagState.showSuggestions || tagState.suggestions.length === 0) {
                return;
            }

            let newPos = (tagState.pos + 1) % tagState.suggestions.length;
            setTagState(state => ({...state, pos: newPos}));
        }
        else if (event.key === "ArrowUp") {
            event.preventDefault();

            if (tagState.suggestions === undefined) {
                return;
            }

            if (!tagState.showSuggestions || tagState.suggestions.length === 0) {
                return;
            }

            let newPos = Math.max(tagState.pos - 1, -1);          

            setTagState(state => ({ ...state, pos: newPos}));
        } 
    }

    function showSuggestions() {
        setTagState(state => ({...state, showSuggestions: true, suggestions: matchingSuggestions(state.input), pos: -1}));
    }

    function hideSuggestions() {
        setTagState(state => ({...state, showSuggestions: false, pos: -1}));
      }    
    
    function onSelectSuggestion(tagName: string) {
        addTag(tagName);
        setTagState(state => ({...state, input: "", suggestions: matchingSuggestions(""), pos: -1}));

        triggerRef.current?.focus();
    }

    function handleChange(e: any) {
        let inputValue = e.target.value;

        const suggestions = matchingSuggestions(inputValue);

        setTagState(state => ({...state, input: inputValue, suggestions: suggestions, pos: -1}));
    }

    function handleAddTag() {
        if (tagState.pos === -1 && tagState.input) {
            const success = addTag(tagState.input.trim());

            if (!success) {
                return;
            }
        }
        else if (tagState.pos !== -1 && tagState.suggestions !== undefined) {
            addTag(tagState.suggestions[tagState.pos].trim());    
        }
        else if (!tagState.input) {
            return;
        }

        setTagState(state => ({...state, input: "", suggestions: matchingSuggestions(""), pos: -1}));
    }

    const toggleRecent = () => {
        setTagState(prevState => {
            return { ...prevState, showRecent: !prevState.showRecent };
        });
    }

    const matchingSuggestions = (input: string) => {
        const limit = 20;
        return tagSuggestionsService.filter(type, input, limit);
    };

    function showStyleModal(tagName: string) {
        setStyleModalState(state => ({ 
            show: true,
            tagName: tagName,
            style: defaultStyles[type]
        }));
    }

    async function saveStyles(tagName: string, style: TagStyle) {

        try {
            await sleepLogSettingsService.saveCustomTagStyle(tagName, style);
        }
        catch (e) {
            addStatus({
                type: StatusMessageType.Fail,
                msg: "Saving styles failed."
            });
            return;
        }

        setStyleModalState(state => {
            state.show = false; 
        });
    }

    function onTagAction(action: TagAction, index: number, tagName: string) {
        switch(action) {
            case "delete":
                deleteTag(index, tagName);
                break;
            case "style":
                showStyleModal(tagName);
                break;
            case "moveLeft":
                moveTag(index, _.clamp(index - 1, 0, tags.length));
                break;
            case "moveRight":
                //
                // + 2 is intentional. Imagine elements:
                // 0    1    2   3
                // [a]  [b]  [c] [d]
                //
                // To move b after c, you need to move it to position 3.
                //
                moveTag(index, _.clamp(index + 2, 0, tags.length));
                break;
            default:
                throw new Error("Unexpected action.");
        }
    }

    const inputSize = Math.max(placeholder.length, tagState.input.length);
    const inputHtmlSize = inputSize === 0 ? 1 : Math.ceil(inputSize);

    return (
        <>
            <Row className="gx-0 gy-1">

                <Col xs="auto">

                    <div style={{display: "flex", gap: "6px", flexWrap: "wrap"}}>
                        { tags.map((tagName, index) =>
                                <React.Fragment key={index}>
                                    {index === dragOverItem &&
                                        <div className="vr" style={{backgroundColor: "#32a1ce", outline: "1px solid #32a1ce"}}></div>
                                    }
                                    <span
                                        draggable={editable}
                                        onDragStart={ e => dragStart(e, index)}
                                        onDragEnter={ e => { e.preventDefault(); dragEnter(e, index) }}
                                        onDragOver={ e => e.preventDefault()}
                                        onDragEnd={drop}
                                        style={{cursor: "pointer"}}
                                    >
                                        <Tag
                                            className={classNames(tagStyleClasses, { dragging: dragOverItem === index})}
                                            name={tagName} 
                                            showContextMenu={true}
                                            tagStyle={sleepLogSettings?.tagStyles?.[tagName]}
                                            onAction={(action) => onTagAction(action, index, tagName)}
                                        />
                                    </span>
                                </React.Fragment>
                        )}
                        { dragItem !== undefined &&
                            <>
                                { tags.length === dragOverItem &&
                                    <div className="vr" style={{backgroundColor: "#32a1ce", outline: "1px solid #32a1ce"}}></div>
                                }
                                <div 
                                    style={{ width: "4px", height: "auto"}}
                                    onDragEnter={ e => { e.preventDefault(); dragEnter(e, tags.length) }}
                                    onDragOver={ e => e.preventDefault()}
                                    onDragEnd={drop}
                                >
                                </div>
                            </>              
                        }
                        <form className="d-flex">
                        { editable &&
                            <>
                            <div className="d-flex">                 
                                <input type="text"
                                    ref={triggerRef}
                                    className={`form-control py-0 px-1 text-sm ${styles.tagInput}`}
                                    style={{"borderStyle": "dashed"}}
                                    maxLength={maxTagLength}
                                    size={inputHtmlSize}                        
                                    placeholder={placeholder}
                                    value={tagState.input}
                                    onKeyDown={handleKeyPress}
                                    onChange={handleChange}
                                    onFocus={(e: any) => showSuggestions()}
                                    onBlur={hideSuggestions}
                                >
                                </input>
                                <Overlay
                                    show={tagState.showSuggestions}
                                    offset={[0, 5]}
                                    placement="bottom"
                                    target={triggerRef.current}
                                    flip={true}
                                >
                                    {({ ...props }) => (
                                        <div {...props} style={{
                                            position: 'absolute',
                                            zIndex: 10000,
                                            ...props.style,
                                        }}
                                        >
                                            <SuggestionsDropdown
                                                tagState={tagState}
                                                onSelect={onSelectSuggestion} 
                                            />
                                        </div>
                                    )}
                                </Overlay>                                                     
                            </div>
                            <button type="submit"
                                className={`btn btn-sm btn-create py-0 text-sm ${styles.addTag}`}
                                onClick={(e) => { e.preventDefault(); handleAddTag(); triggerRef.current?.focus(); }}
                            >
                                +
                            </button>
                            { showRecent &&               
                                <button type="button"
                                    className="btn btn-outline-secondary py-0 px-1 ms-4 text-sm" 
                                    onClick={(e) => toggleRecent()}
                                >
                                    {tagState.showRecent ? "Hide" : "Recent"}
                                </button>
                            }
                            </>
                        }
                        </form> 
                    </div>

                    { tagState.showRecent && editable &&
                        <div 
                            className="d-flex border rounded mt-2 p-2 shadow-sm"
                            style={{gap: "6px", flexWrap: "wrap", maxHeight: "150px", overflow: "auto"}}
                        >
                            { tagState.recent?.map(tag =>
                                <span onClick={e => addTag(tag)}>
                                    <Tag
                                        className={classNames(tagStyleClasses)}
                                        style={{cursor: "pointer"}}
                                        name={tag} 
                                        showContextMenu={false}
                                        tagStyle={sleepLogSettings?.tagStyles?.[tag]}
                                    />
                                </span>
                            )}
                            { tagState.recent === undefined &&
                                <>
                                    <Tag
                                        className={classNames(tagStyleClasses, "placeholder-glow")}
                                        style={{cursor: "pointer"}}
                                        name={""} 
                                        showContextMenu={false}
                                    >
                                        <span className="placeholder bg-secondary placeholder-xs" style={{width: "48px"}}></span>
                                    </Tag>
                                    <Tag
                                    className={classNames(tagStyleClasses, "placeholder-glow")}
                                    style={{cursor: "pointer"}}
                                    name={""} 
                                    showContextMenu={false}
                                    >
                                        <span className="placeholder bg-secondary placeholder-xs" style={{width: "64px"}}></span>
                                    </Tag>
                                    <Tag
                                        className={classNames(tagStyleClasses, "placeholder-glow")}
                                        style={{cursor: "pointer"}}
                                        name={""} 
                                        showContextMenu={false}
                                    >
                                        <span className="placeholder bg-secondary placeholder-xs" style={{width: "42px"}}></span>
                                    </Tag>                                    
                                </>
                                
                            }
                        </div>
                    }

                </Col>
            </Row>
            <CustomTagStylesModal
                show={styleModalState.show}
                close={() => setStyleModalState(state => { state.show = false; })}
                tagName={styleModalState.tagName}
                initialStyle={styleModalState.style}
                isSaving={false}
                save={saveStyles}
            />
        </>
    );
}

interface SuggestionProps {
    tagState: TagState;
    onSelect: (tag: string) => void;
}

function SuggestionsDropdown({ tagState, onSelect }: SuggestionProps) {
    const suggestionElements = useRef<HTMLLIElement[]>([]);

    console.log(tagState);

    useEffect(() => {
        const el = suggestionElements.current[tagState.pos];
        if (el) {
            el.scrollIntoView({ block: 'center', behavior: 'auto' });
        }
    }, [tagState.pos]);
    
    function onSelectSuggestion(e: React.MouseEvent<HTMLLIElement, MouseEvent>, tagName: string) {
        if (e.button !== 0) {
            return;
        }

        e.preventDefault();
    
        onSelect(tagName);
    }

    const suggestionsStyle: React.CSSProperties =  {
        fontSize:".9rem", 
        minHeight: "1px",
        minWidth: "100px",
        maxHeight: "300px", 
        overflowY: "auto"
    };

    return (
        <ul 
            className="list-group text-sm shadow"
            style={suggestionsStyle}
        >
            { tagState.suggestions?.map((tag, index) => 
                <li
                    key={index} 
                    className={`${getSuggestionDecoration(tagState, index)} text-nowrap`}
                    style={{cursor:"pointer"}}
                    onMouseDown={e => onSelectSuggestion(e, tag)}
                    ref={(el) => {suggestionElements.current[index] = el!} }
                >
                    {tag}
                </li>
            )}
            { tagState.suggestions === undefined &&
                <>
                    <li 
                        className={`${getSuggestionDecoration(tagState, 0)} text-nowrap`}
                    >
                        <div className="card-title placeholder-glow">
                            <div className="placeholder placeholder-xs bg-secondary w-100"></div>
                        </div>
                    </li>
                    <li 
                        className={`${getSuggestionDecoration(tagState, 0)} text-nowrap`}
                    >
                        <div className="card-title placeholder-glow">
                            <div className="placeholder placeholder-xs bg-secondary w-100"></div>
                        </div>
                    </li>
                    <li 
                        className={`${getSuggestionDecoration(tagState, 0)} text-nowrap`}
                    >
                        <div className="card-title placeholder-glow">
                            <div className="placeholder placeholder-xs bg-secondary w-100"></div>
                        </div>
                    </li>
                </>
            }
        </ul>
    );
};

function getSuggestionDecoration(tagState: TagState, index: number) {
    // Larger padding on small screens to prevent tapping wrong option
    let classNames = `list-group-item ${styles.listItem}`;
    
    if (tagState.pos === index) {
        classNames += " selected-suggestion";
    }
    else {
        classNames += " hoverable-suggestion";            
    }

    return classNames;
}